import React from "react";
import Box from '@mui/material/Box';
import { Grid, Typography } from "@mui/material";
import Link from '@mui/material/Link';
import { ROOT } from "../../utils/constants";

const Links = () => {
    let links = {
        "Trees Basics": ROOT + "/library?blogId=dsa",
        "DSA Basics": "",
        "Concurrency Tutorial 1": "",
        "Concurrency Tutorial 2": ""
    };

    let sections = {
        "Data Sctructures and Algorithms": ["Trees Basics", "DSA Basics"],
        "Low Level Design : Concurrency": ["Concurrency Tutorial 1", "Concurrency Tutorial 2"]
    };

    return (

        <Box sx={{ flexGrow: 1, mt: "60px", mr: "15%", ml: "15%" }}>
            <Grid container>

                {Object.entries(sections).map(([key, valueList]) => (
                    <Grid item xs={6}>
                        <h4> {key} </h4>
                        <ul>
                            {valueList.map((value: string, index) => (
                                <li key={index}>
                                    <Link href={links[value as keyof typeof links]} target="_blank">{value}</Link>    
                                </li>
                            ))}
                        </ul>
                    </Grid>
                ))}
                
            </Grid>
        </Box>

    )
}

export default Links;