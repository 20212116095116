import {
  createContext,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import React, { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
  User,
  GithubAuthProvider,
} from "firebase/auth";
import { auth } from "../firebase";

export interface IAuthContext {
  user: any;
  googleSignIn: () => void;
  githubSignIn: () => void;
  logOut: () => void;
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  googleSignIn: () => {},
  githubSignIn: () => {},
  logOut: () => {},
});

const AuthContextProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState(null);

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
    // signInWithRedirect(auth, provider)
  };

  const githubSignIn = () => {
    const provider = new GithubAuthProvider();
    signInWithPopup(auth, provider);
    // signInWithRedirect(auth, provider)
  };

  const logOut = () => {
    signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      /* eslint-disable-next-line */
      setUser(currentUser as SetStateAction<null>);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ googleSignIn, githubSignIn, logOut, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};

export { AuthContextProvider };
