import React from 'react';
import { Typography } from '@mui/material';
import { Container } from '@mui/system';

export default function AboutUs() {
  return (
    <Container sx={{ paddingX: 4 }}>
      <Typography variant="h2">About Us</Typography>
      <Typography sx={{ paddingBottom: 2 }}>
        Welcome to Interview Topper, your one-stop destination for mastering
        interviews and landing your dream job. We understand that interviews can
        be daunting, and the pressure to perform well can be overwhelming. Our
        mission is to empower job seekers like you with the knowledge, skills,
        and confidence needed to excel in interviews and stand out from the
        competition.
      </Typography>
      <Typography sx={{ paddingBottom: 2 }}>
        At Interview Topper, we offer a wide range of resources and tools to
        help you prepare for interviews effectively. Our platform provides
        expertly crafted interview tips, comprehensive interview guides, and
        industry-specific advice to ensure you are well-prepared for any
        interview scenario.
      </Typography>
      <Typography sx={{ paddingBottom: 2 }}>
        Our team of experienced professionals and career advisors are dedicated
        to equipping you with the insights and strategies you need to
        communicate your strengths, showcase your accomplishments, and
        effectively answer challenging interview questions.
      </Typography>
      <Typography sx={{ paddingBottom: 2 }}>
        Whether you are a recent graduate, a mid-career professional, or someone
        looking to make a career change, Interview Topper has the resources to
        support you at every stage of your job search journey. We believe that
        every individual has unique talents and potential, and our goal is to
        help you unlock yours and find the perfect job match.
      </Typography>
      <Typography sx={{ paddingBottom: 2 }}>
        Join us today and embark on the path to interview success. Let's
        collaborate, learn, and grow together. Together, we can make your career
        aspirations a reality.
      </Typography>
      <Typography variant="h3" sx={{ paddingBottom: 2 }}>
        Our Vision
      </Typography>
      <Typography sx={{ paddingBottom: 2 }}>
        Our vision is to be the leading platform that empowers job seekers
        worldwide to achieve interview excellence and career success. We aim to
        bridge the gap between job seekers and their dream opportunities by
        providing them with the tools, knowledge, and support they need to
        outshine in interviews and secure their desired positions.
      </Typography>
      <Typography variant="h3" sx={{ paddingBottom: 2 }}>
        Contact Us
      </Typography>
      <Typography>
        If you have any questions, suggestions, or feedback, we would love to
        hear from you. Feel free to reach out to our friendly support team at
        interviewtopper23@gmail.com, and we'll be happy to assist you on your
        journey to interview success.
      </Typography>
    </Container>
  );
}
