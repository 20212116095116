import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";

const Video = styled("iframe")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
});

function YoutubeEmbed(props: any) {
  return (
    <Video
      className="video"
      src={`https://www.youtube.com/embed/${props.embedId}`}
      title="Course Video"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></Video>
  );
}

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
