import type { Mentor } from "../interfaces/mentor";

export const data: Array<Mentor> = [
  {
    id: 1,
    photo: "/images/mentors/PoojaPic.png",
    name: "Pooja",
    category: "Software Engineer",
    description:
      "One of the great aspects of Interview Topper is their ability to provide comprehensive guidance on the technical aspects of the interview. Their instructors have an in-depth knowledge of the latest technologies and frameworks. Their expertise helped me easily navigate the complex coding exercises and technical questions.",
    company: {
      name: "Grab",
      logo: "/images/companies/AkridataLogo.jpg",
    },
  },
  {
    id: 2,
    photo: "/images/mentors/ShivamShreeGupta.jpg",
    name: "Shivamshree Gupta",
    category: "Full Stack (React)",
    description:
      "I had upcoming interviews with the top Tech giants, so I decided to enroll in the Advanced Crash Course of DSA and Low-Level Design offered by the interview topper. I was amazed at how quickly they covered advanced DSA concepts like Segment Trees, Red-Black Trees, and Trie Trees, as well as Graphs with Max Flow and Min-Cut problems. These courses proved instrumental in helping me crack Sprinklr.",
    company: {
      name: "Sprinklr",
      logo: "/images/companies/SprinklrLogo.png",
    },
  },
  {
    id: 3,
    photo: "/images/mentors/noah-buscher-8A7fD6Y5VF8-unsplash.jpg",
    name: "Kirti Gaur",
    category: "Full Stack",
    description:
      "Their courses are extremely beginner-friendly and the experience which their instructors provide in learning is never to be found anywhere else. Grasping concepts like graphs, greedy and dynamic programming is effortless, including advanced DS like Tries. I never imagined becoming this proficient in data structures and algorithms and learning could be this much fun.",
    company: {
      name: "Qualcomm",
      logo: "/images/companies/QualcommLogo.png",
    },
  },
  {
    id: 4,
    photo: "/images/mentors/noah-buscher-8A7fD6Y5VF8-unsplash.jpg",
    name: "Ms. Gupta",
    category: "Full Stack",
    description:
      "Interview Topper's courses are excellent for experienced professionals. Their instructors are great at teaching topics like Apache Kafka, Redis, and MongoDB, which helped me breeze through interviews. They also provided valuable preparation for behavioral interviews. Highly recommended for interview success!",
    company: {
      name: "Intuit",
      logo: "/images/companies/IntuitLogo.png",
    },
  },
  {
    id: 5,
    photo: "/images/mentors/ShishirPic.jpg",
    name: "Shishir Singh",
    category: "Full Stack - SDE-II",
    description:
      "The interview Topper platform is a game-changer for interview preparation. The curriculum is designed for beginners as well as advanced skillsets in Low-Level as well as High-Level Design. I highly recommend their crash courses to anyone seeking success in Tech interviews.",
    company: {
      name: "Microsoft",
      logo: "/images/companies/microsoft.png",
    },
  },
];
