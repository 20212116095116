import HomePopularCourse from "../components/home/popular-courses";
import HomeHero from "../components/home/hero";
import HomeTestimonial from "../components/home/testimonial";
import HomeCompanies from "../components/home/companies";
import ContactFab from "../components/home/contact-fab";
import HomeFeature from "../components/home/feature";

export default function Home() {
  return (
    <>
      <ContactFab />
      <HomeHero />
      <HomePopularCourse />
      <HomeFeature />
      <HomeTestimonial />
      <HomeCompanies />
    </>
  );
}
