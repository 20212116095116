import React, { useState } from "react";
import {
  Grid,
  Divider,
  Avatar,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { MuiTelInput } from "mui-tel-input";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { API_ROOT } from "../../utils/constants";

const FlexCenteredContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const FormContainer = styled(Grid)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
}));

const EnrolledCoursesList = styled(List)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const ProfileForm = (props: any) => {
  const [isEditable, setIsEditable] = useState(false);

  const initialFormData = props.initialFormData;

  // State to manage form data
  const [formData, setFormData] = useState(initialFormData);

  // Handle form field changes
  const handleChange = (e: any) => {
    let name = "";
    let value = "";

    if (e.target) {
      name = e.target.name;
      value = e.target.value;
    } else {
      if (e.$d) {
        name = "doj";
        value = e.$d;
      } else {
        name = "phoneNo";
        value = e;
      }
    }

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle Edit button click to toggle editable state
  const handleEditClick = () => {
    setIsEditable((prevState) => !prevState);

    if (isEditable) {
      // Making Post API call to save/update data.
      fetch(API_ROOT + "/_api/v1/user-profile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + props.accessToken,
        },
        body: JSON.stringify(formData),
      });
    }
  };

  return (
    <FlexCenteredContainer>
      <Typography variant="h5" gutterBottom>
        Profile
      </Typography>
      <form noValidate autoComplete="off" style={{ textAlign: "center" }}>
        <TextField
          sx={{ width: "80%" }}
          label="Name"
          variant="outlined"
          name="name"
          value={formData.name}
          onChange={handleChange}
          disabled={true}
          margin="normal"
        />
        <TextField
          sx={{ width: "80%" }}
          label="Email"
          variant="outlined"
          name="email"
          value={formData.email}
          onChange={handleChange}
          disabled={true}
          margin="normal"
        />
        <MuiTelInput
          value={formData.phoneNo}
          onChange={handleChange}
          name="phoneNo"
          sx={{ width: "80%" }}
          disabled={!isEditable}
        />
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          value={formData.type}
          onChange={handleChange}
          sx={{ width: "80%", mt: "8px", ml: "auto", mr: "auto" }}
        >
          <Grid container>
            <FormControlLabel
              disabled={!isEditable}
              name="type"
              value="0"
              control={<Radio />}
              label="Student"
            />
            <FormControlLabel
              disabled={!isEditable}
              name="type"
              value="1"
              control={<Radio />}
              label="Working Professional"
            />
          </Grid>
        </RadioGroup>
        <TextField
          sx={{ width: "80%" }}
          label={formData.type == "0" ? "College Name" : "Current company"}
          variant="outlined"
          name="institutionName"
          value={formData.institutionName}
          onChange={handleChange}
          disabled={!isEditable}
          margin="normal"
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ width: "80%", mt: "8px" }}
            label={formData.type == "0" ? "Graduation Date" : "Joining Date"}
            value={dayjs(formData.doj)}
            disabled={!isEditable}
            onChange={handleChange}
          />
        </LocalizationProvider>

        <br />
        <Button
          variant="contained"
          onClick={handleEditClick}
          style={{ marginTop: "20px" }}
        >
          {isEditable ? "Save" : "Edit"}
        </Button>
      </form>
    </FlexCenteredContainer>
  );
};

const ProfilePage = (props: any) => {
  let initialFormData = {
    id: props.userDetails.uid,
    name: props.userDetails.displayName,
    email: props.userDetails.email,
    phoneNo: props.userProfile?.phoneNo ?? "+91",
    type: props.userProfile?.type ?? 0, // 0 - Student, 1- Working Professional
    institutionName: props.userProfile?.institutionName ?? "",
    doj:props.userProfile?.doj ?? "",
  };

  // Todo : Pass this prop from parent.
  let courses: string[] = props.userProfile?.courses ?? [];

  const name = props.userDetails.displayName;
  const image = props.userDetails.photoURL;

  return (
    <Grid container spacing={2} sx={{ mt: 8 }}>
      {/* First Part */}
      <Grid item xs={12} sm={2}>
        <FlexCenteredContainer sx={{ height: "100%", mt: "20px", ml: "10px" }}>
          <Avatar
            alt={name}
            src={image}
            style={{
              width: "120px",
              height: "120px",
              borderRadius: "50%",
              marginBottom: "20px",
            }}
          />
          <Typography variant="h6">{name}</Typography>
          <Typography variant="subtitle1">{props.userDetails.email}</Typography>
        </FlexCenteredContainer>
      </Grid>

      <Divider orientation="vertical" flexItem />

      {/* Second Part */}
      <Grid item xs={12} sm={7}>
        <ProfileForm
          initialFormData={initialFormData}
          accessToken={props.userDetails?.accessToken}
        />
      </Grid>

      <Divider orientation="vertical" flexItem />

      {/* Third Part */}
      <Grid item xs={12} sm={2}>
        <FormContainer>
          <Typography variant="h6">Enrolled Courses</Typography>
          <EnrolledCoursesList>
            {courses.map((course, index) => (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar>{index + 1}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={course} />
              </ListItem>
            ))}
          </EnrolledCoursesList>
        </FormContainer>
      </Grid>
    </Grid>
  );
};

export default ProfilePage;
