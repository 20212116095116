import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQPage = () => {
  const faqData = [
    {
      question: "Is there any number to contact for query?",
      answer:
        "You may call us on our number: +91-9980452799 or Drop us an email at courses@interviewtopper.com",
    },
    {
      question: "How long will the course content be available for?",
      answer:
        "The recordings as well as the course content will be available for a year after your course completion.",
    },
    {
      question: "How can I resolve my doubts?",
      answer:
        "We recommend you to note down your doubts and resolve them on biweekly doubt resolution live sessions.",
    },
    {
      question: "Can I pay in EMIs?",
      answer:
        "Once you pay the enrollment fee via a credit card, there would come an option to pay via the EMIs.",
    },
    {
      question: "Do I get 1:1 mentorship sessions?",
      answer:
        "Yes, apart from crash courses, all other courses do have 1:1 mentorship sessions with industry veterans",
    },
    {
      question: "What about mock interviews?",
      answer:
        "Apart from crash courses, rest all courses do have regular mock interviews.",
    },
    {
      question: "What about the placment support?",
      answer:
        "We do provide placement support based upon your attendance in the classes and performance in mock interviews.",
    },
    {
      question: "How will these classes be conducted?",
      answer: `It will be a mixture of Live Stream classes and some pre-recorded lectures. You can attend these classes from any geographical 
      location. These are interactive live sessions, where you can ask your doubts to the instructor. You just need to have working 
      internet and a PC/Laptop.`,
    },
  ];

  return (
    <Container maxWidth="md">
      <Typography
        variant="h4"
        style={{
          marginBottom: "1rem",
          textAlign: "center",
          paddingTop: "1rem",
        }}
      >
        Frequently Asked Questions
      </Typography>
      {faqData.map((faq, index) => (
        <Accordion
          key={index}
          style={{ borderRadius: "8px", marginBottom: "0.5rem" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{ borderRadius: "8px" }}
          >
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default FAQPage;
