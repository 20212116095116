import { Typography } from "@mui/material";
import { Container } from "@mui/system";

export default function TermsAndConditions() {
  return (
    <Container>
      <Typography variant="h2">Terms and Conditions</Typography>
      <Typography>
        Welcome to interviewtopper.com! These terms and conditions outline the
        rules and regulations for the use of our website, located at
        https://interviewtopper.com. By accessing this website, we assume you
        accept these terms and conditions. If you do not agree with any part of
        these terms, please do not continue to use interviewtopper.com. The
        following terminology applies to these Terms and Conditions, Privacy
        Statement, and Disclaimer Notice, and all Agreements: "Client", "You",
        and "Your" refers to you, the person accessing this website and
        compliant to the Company's terms and conditions. "The Company",
        "Ourselves", "We", "Our", and "Us" refer to our Company. "Party",
        "Parties", or "Us" refers to both the Client and ourselves. All terms
        refer to the offer, acceptance, and consideration of payment necessary
        to undertake the process of our assistance to the Client in the most
        appropriate manner for the express purpose of meeting the Client's needs
        in respect of the provision of the Company's stated services, in
        accordance with and subject to the prevailing law of Netherlands. Any
        use of the above terminology or other words in the singular, plural,
        capitalization, and/or he/she or they are taken as interchangeable and,
        therefore, as referring to the same.
      </Typography>

      <Typography variant="h2">Cookies</Typography>
      <Typography>
        We employ the use of cookies. By accessing interviewtopper.com, you
        agree to use cookies in agreement with the interviewtopper.com's Privacy
        Policy. Most interactive websites use cookies to let us retrieve the
        user's details for each visit. Cookies are used by our website to enable
        the functionality of certain areas to make it easier for people visiting
        our website. Some of our affiliate/advertising partners may also use
        cookies.
      </Typography>

      <Typography variant="h2">License</Typography>
      <Typography>
        Unless otherwise stated, interviewtopper.com and/or its licensors own
        the intellectual property rights for all material on
        interviewtopper.com. All intellectual property rights are reserved. You
        may access this from interviewtopper.com for your own personal use
        subjected to restrictions set in these terms and conditions.
      </Typography>
      <ul>
        <li>Republish material from interviewtopper.com</li>
        <li>Sell, rent, or sub-license material from interviewtopper.com</li>
        <li>Reproduce, duplicate, or copy material from interviewtopper.com</li>
        <li>Redistribute content from interviewtopper.com</li>
      </ul>
      <Typography>
        You hereby grant interviewtopper.com a non-exclusive license to use,
        reproduce, edit, and authorize others to use, reproduce, and edit any of
        your testimonials in any and all forms, formats, or media. No use of
        interviewtopper.com's logo or other artwork will be allowed for linking
        absent a trademark license agreement.
      </Typography>

      <Typography variant="h2">iFrames</Typography>
      <Typography>
        Without prior approval and written permission, you may not create frames
        around our Webpages that alter in any way the visual presentation or
        appearance of our Website.
      </Typography>

      <Typography variant="h2">Content Liability and Your Privacy</Typography>
      <Typography>
        Please read our Privacy Policy to understand how we collect, use, and
        protect your information. We aim to respect your privacy and ensure the
        security of your personal data in accordance with applicable laws.
        However, we do not take any liability for the content posted by users or
        third-party websites that may be linked from interviewtopper.com. We
        encourage users to exercise caution and discretion when sharing personal
        information online.
      </Typography>

      <Typography variant="h2">Reservation of Rights and Disclaimer</Typography>
      <Typography>
        To the maximum extent permitted by applicable law, we exclude all
        representations, warranties, and conditions relating to our website and
        the use of this website. Nothing in this disclaimer will limit or
        exclude our or your liability for death or personal injury resulting
        from negligence, fraud, or any other liability that cannot be excluded
        or limited by applicable law. As long as the website and the information
        and services on the website are provided free of charge, we will not be
        liable for any loss or damage of any nature.
      </Typography>
    </Container>
  );
}
