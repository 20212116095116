import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ContactForm from "../home/contact-form";

const ContactUsPage = () => {
  return (
    <Grid container spacing={10} mt={3} style={{ marginLeft: "auto" }}>
      <Grid item xs={5}>
        <img
          src="\images\ContactUsImage\ContactUsImage.png"
          alt="Image"
          style={{ height: "100%", width: "100%" }}
        />
      </Grid>

      <Grid item xs={5}>
        <ContactForm />
      </Grid>
    </Grid>
  );
};

export default ContactUsPage;
