import React from "react";
import Box from '@mui/material/Box';
import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import HomePopularCourse from "../home/popular-courses";
import Stack from '@mui/material/Stack';

const Video = styled("iframe")({
    width: "700px",
    height: "450px",
    borderRadius: "20px",
});


const BlogPage = (props: any) => {
    let blogObj = require("../../blogs/" + props.blogId + ".json");

    return (

        <Box sx={{ flexGrow: 1, mt: "60px" }}>
            <Grid container>
                <Grid item xs={12} sx={{ mr: "15%", ml: "15%" }}>
                    {/* <Typography variant="h2" sx={{}}> This is title of vlog.</Typography> */}
                    <Typography
                        component="mark"
                        variant="h1"
                        sx={{
                            position: "relative",
                            color: "primary.main",
                            backgroundColor: "unset",
                            fontSize: "2.5rem"
                        }}
                    >
                        {blogObj?.blogTitle}
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{ mr: "15%", ml: "15%" }}>
                    <span>Watch Video Explanation</span>
                </Grid>

                <Grid item xs={12} sx={{ mt: "20px", mr: "15%", ml: "15%" }}>
                    {blogObj?.paraList ? (
                        blogObj.paraList.map((para: string, index: any) => (
                            // <div key={index}>{para}</div>
                            <Typography
                                variant="body1"
                                fontSize={"1.2rem"}
                                sx = {{mb: "10px"}}
                            >
                                {para}
                            </Typography>
                        ))
                    ) : null}
                </Grid>

                <Grid item xs={12} sx={{ mt: "40px", mr: "15%", ml: "15%" }}>
                    <Stack direction="row" justifyContent="center">
                        <Video
                            className="video"
                            src={`https://www.youtube.com/embed/${blogObj.vedioEmbeddId}`}
                            title="Course Video"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></Video>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <HomePopularCourse dontShowTitle={true} />
                </Grid>
            </Grid>

        </Box>

    )
}

export default BlogPage