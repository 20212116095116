import {
  Box,
  Button,
  TextField
} from "@mui/material";
import { Container, flexbox } from "@mui/system";
import { MuiTelInput } from "mui-tel-input";
import React, { FC, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { AuthContext } from "../../context/auth-context";
import { API_ROOT } from "../../utils/constants";

interface IProps {
  handleClose?: () => void;
}

const { v4: uuidv4 } = require('uuid');

const ContactForm: FC<IProps> = (props: IProps) => {
  const { user } = React.useContext(AuthContext);

  let initialFormData = {
    id: user?.uid ?? "random_"+uuidv4(),
    name: "",
    email: "",
    phoneNo: "+91",
    comments: "",
    hasContacted: "No"
  }

  // State to manage form data
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e: any) => {
    let name = "";
    let value = "";

    if (e.target) {
      name = e.target.name;
      value = e.target.value;
    }
    else {
      name = "phoneNo";
      value = e;
    }

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));

  }

  const handleCallback = () => {
    fetch(API_ROOT + "/_api/v1/request-callback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user?.accessToken,
      },
      body: JSON.stringify(formData),
    })

    if (props.handleClose) {
      props.handleClose();
    } 
  }

  return (
    <Container>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: { xs: "20ch", md: "40ch" } },
          display: "flex",
          flexDirection: "column",
        }}
        autoComplete="off"
      >
        <TextField
          id="outlined-basic"
          label="Name"
          variant="outlined"
          name = "name"
          onChange={handleChange}
          value = {formData.name}
          required
        />
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          onChange={handleChange}
          type="email"
          name="email"
          value={formData.email}
          required
        />
        <MuiTelInput value = {formData.phoneNo} name ="phoneNo" onChange={handleChange} />
        <TextField
          id="outlined-multiline-static"
          label="Comments"
          name="comments"
          value = {formData.comments}
          multiline
          rows={4}
          onChange={handleChange}
          defaultValue=""
        />
      </Box>

      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1 },
          display: "flex",
          flexWrap: "wrap",
        }}
        noValidate
        autoComplete="off"
      >
        <Button variant="contained" endIcon={<SendIcon />} onClick={handleCallback}>
          Send
        </Button>
        {props.handleClose && (
          <Button variant="outlined" onClick={props.handleClose}>
            Close
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default ContactForm;
