import React, { useState } from "react";
import { styled } from "@mui/system";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  IconButton,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import TimelineIcon from "@mui/icons-material/Timeline";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import YoutubeEmbed from "./youtubeEmbedding";
import CourseLectureList from "./course-lecture-list";
import CheckoutRedirect from "../payment/checkout-redirect";
import { AuthContext } from "../../context/auth-context";
import Slide, { SlideProps } from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import { CheckoutLoadingButton } from "../styled-button";

type TransitionProps = Omit<SlideProps, "direction">;

const RootCard = styled(Card)({
  width: "90%",
  margin: "auto",
  marginTop: 50,
  borderRadius: 16,
  position: "relative",
  overflow: "hidden",
});

const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 20,
  paddingLeft: 20,
  paddingRight: 20,
});

const Title = styled("div")({
  display: "flex",
  alignItems: "center",
});

const TitleText = styled(Typography)({
  fontSize: "1rem",
  fontWeight: "bold",
  marginRight: 5,
});

const Content = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
});

const VideoContainer = styled("div")({
  position: "relative",
  paddingTop: "56.25%", // 16:9 aspect ratio
  marginBottom: 20,
  borderRadius: 16,
  overflow: "hidden",
});

const PricingContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  marginRight: 10,
});

const Price = styled(Typography)({
  fontSize: "1.2rem",
  fontWeight: "bold",
  marginRight: 5,
});

const OriginalPrice = styled(Typography)({
  textDecoration: "line-through",
  color: "#999",
  marginRight: 5,
});

const EnrollContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  marginTop: 20,
});

const Discount = styled(Typography)({
  color: "#4caf50",
  fontWeight: "bold",
});

const IconContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: 10,
});

const AdditionalText = styled("div")({
  marginTop: 20,
  paddingLeft: 20,
  paddingRight: 20,
});

const EnrollButton = styled(Button)({
  borderRadius: 8,
});

const ExpandButton = styled(IconButton)({
  position: "absolute",
  bottom: 0,
  right: 0,
  margin: 10,
});

function TransitionRight(props: TransitionProps) {
  return <Slide {...props} direction="right" />;
}

const CourseDetails = (props: any) => {
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [transition, setTransition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
  >(undefined);

  const handleNonLoggedInUsers =
    (Transition: React.ComponentType<TransitionProps>) => () => {
      setTransition(() => Transition);
      setOpen(true);
    };

  const handleClose = () => {
    setOpen(false);
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <RootCard>
      <CardContent>
        <Header>
          <Title>
            <TitleText>{props.courseDetail.name}</TitleText>
          </Title>
          <EnrollContainer>
            <PricingContainer>
              <Price>₹{props.courseDetail.price.advanceDiscount}</Price>
              <OriginalPrice>
                ₹{props.courseDetail.price.actualPrice}
              </OriginalPrice>
              <Discount>
                {Math.round(
                  ((props.courseDetail.price.actualPrice * 1 -
                    props.courseDetail.price.advanceDiscount * 1) /
                    props.courseDetail.price.actualPrice) *
                    1 *
                    100
                )}
                % off
              </Discount>
            </PricingContainer>
            <CheckoutLoadingButton courseDetail={props.courseDetail} />
          </EnrollContainer>
        </Header>

        <Content>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              {React.useContext(AuthContext)?.user == null ? (
                <img
                  src="\images\CoursePageVideoIcon\videoIcon.png"
                  alt="Image"
                  onClick={handleNonLoggedInUsers(TransitionRight)}
                  style={{ cursor: "pointer", maxHeight: "80%", width: "100%" }}
                />
              ) : (
                <VideoContainer>
                  <YoutubeEmbed embedId={props.courseDetail.youtubeEmbedId} />
                </VideoContainer>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <IconContainer>
                <AccessTimeIcon sx={{ mr: 3 }} />
                <Typography variant="body1" component="p">
                  Course Duration : {props.courseDetail.duration}
                </Typography>
              </IconContainer>
              <IconContainer>
                <PersonIcon sx={{ mr: 3 }} />
                <Typography variant="body1" component="p">
                  Instructor : {props.courseDetail.instructor}
                </Typography>
              </IconContainer>
              <IconContainer>
                <TimelineIcon sx={{ mr: 3 }} />
                <Typography variant="body1" component="p">
                  Timeline
                </Typography>
              </IconContainer>
              <IconContainer>
                <LiveTvIcon sx={{ mr: 3 }} />
                <Typography variant="body1" component="p">
                  Live Classes
                </Typography>
              </IconContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <Typography variant="h6" gutterBottom>
                  Course Description
                </Typography>
                <Typography variant="body1" component="p">
                  {props.courseDetail.overview} <br />{" "}
                  {props.courseDetail.details} <br />{" "}
                  {props.courseDetail.highlights}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Content>

        <ExpandButton
          sx={{ transform: expanded ? "rotate(180deg)" : "rotate(0deg)" }}
          onClick={handleExpandClick}
        >
          <ExpandMoreIcon />
        </ExpandButton>
        <AdditionalText sx={{ display: expanded ? "block" : "none" }}>
          <CourseLectureList lectures={props.courseDetail.curriculum} />
        </AdditionalText>
      </CardContent>
      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={transition}
        message="Please login to view sample videos."
        key={transition ? transition.name : ""}
      />
    </RootCard>
  );
};

export default CourseDetails;
