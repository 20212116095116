import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface Props {
  onClick?: () => void;
  variant?: "primary" | "secondary";
}

const Logo: FC<Props> = ({ onClick, variant }) => {
  return (
    <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
      <Box
        onClick={onClick}
        sx={{ display: "flex", alignItems: "center", m: 0, p: 0 }}
      >
        <img
          src="/images/logo.png"
          alt="Headline curve"
          width={"48px"}
          height={"auto"}
        />
        <Box sx={{ ml: 1 }}>
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontWeight: 700,
              "& span": {
                color: variant === "primary" ? "primary.main" : "unset",
              },
            }}
          >
            Interview{""}
            <span>Topper</span>
          </Typography>
          <Typography sx={{ fontWeight: 1000, fontSize: 11 }}>
            GIVING DIRECTION TO YOUR EFFORTS
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

Logo.defaultProps = {
  variant: "primary",
};

export default Logo;
