export const companiesData: any[] = [
  {
    src: "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/256211ad-d248-43f6-9361-f9f666b25300/public",
    sizes: "(max-width: 479px) 100vw, 170px",
    srcSet:
      "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/256211ad-d248-43f6-9361-f9f666b25300/h=500 1x , https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/256211ad-d248-43f6-9361-f9f666b25300/h=800 2x",
  },
  {
    src: "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/b6975c88-b230-4c80-05de-523799dea000/public",
    sizes: "(max-width: 479px) 100vw, 160px",
    srcSet:
      "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/b6975c88-b230-4c80-05de-523799dea000/h=500 1x ,https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/b6975c88-b230-4c80-05de-523799dea000/h=800 2x",
  },
  {
    src: "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/bf9f88fb-c486-4425-d4ba-de51b8d27f00/public",
    sizes: "(max-width: 479px) 100vw, 170px",
    srcSet:
      "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/bf9f88fb-c486-4425-d4ba-de51b8d27f00/h=500 1x,https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/bf9f88fb-c486-4425-d4ba-de51b8d27f00/h=800 2x",
  },
  {
    src: "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/f3d71fd8-64c8-451f-bf95-df47a432b800/public",
    sizes: "(max-width: 479px) 100vw, 170px",
    srcSet:
      "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/f3d71fd8-64c8-451f-bf95-df47a432b800/h=500 1x,https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/f3d71fd8-64c8-451f-bf95-df47a432b800/h=800 2x",
  },
  {
    src: "/images/companies/SprinklrLogo.png",
    sizes: "(max-width: 479px) 100vw, 170px",
  },
  {
    src: "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/b685226e-d473-4917-555c-461a1302b300/public",
    sizes: "(max-width: 479px) 100vw, 170px",
    srcSet:
      "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/b685226e-d473-4917-555c-461a1302b300/h=400 1x , https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/b685226e-d473-4917-555c-461a1302b300/h=800 2x",
  },
  {
    src: "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/465992cf-1519-4dbc-71ab-457868d6a600/public",
    sizes: "(max-width: 479px) 100vw, 150px",
    srcSet:
      "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/465992cf-1519-4dbc-71ab-457868d6a600/h=400 1x , https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/465992cf-1519-4dbc-71ab-457868d6a600/h=800 2x",
  },
  {
    src: "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/eaa557ab-6445-46b6-1c0b-fed8a77bf600/public",
    sizes: "(max-width: 479px) 100vw, 150px",
    srcSet:
      "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/eaa557ab-6445-46b6-1c0b-fed8a77bf600/h=300 1x,https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/eaa557ab-6445-46b6-1c0b-fed8a77bf600/h=600 2x",
  },
  {
    src: "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/380e8284-812e-4235-cf3c-7c19c630d200/public",
    sizes: "(max-width: 479px) 100vw, 150px",
    srcSet:
      "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/380e8284-812e-4235-cf3c-7c19c630d200/h=500 1x,https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/380e8284-812e-4235-cf3c-7c19c630d200/h=800 2x",
  },
  {
    src: "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/44878280-dd42-4b9e-c76d-ae235d072f00/public",
    sizes: "(max-width: 479px) 100vw, 150px",
    srcSet:
      "https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/44878280-dd42-4b9e-c76d-ae235d072f00/h=500 1x,https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/44878280-dd42-4b9e-c76d-ae235d072f00/h=800 2x",
  },
  {
    src: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Intuit_Logo.svg/1200px-Intuit_Logo.svg.png",
    sizes: "(max-width: 479px) 100vw, 150px",
  },
  {
    src: "/images/companies/AkridataLogo.jpg",
    sizes: "(max-width: 479px) 100vw, 150px",
  },
];
