import React, { ReactNode } from "react";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

interface Data {
  title: string;
  description: string;
  image?: string;
}

export const data: Data[] = [
  {
    title: "Mentor",
    description:
      "1:1 mentorship with Engineers of Microsoft, Sprinklr, Qualcomm.",
    image: "/images/icons/mentor.png",
  },
  {
    title: "Structured Curriculum",
    description:
      "Structured Curriculum to build the upon the concepts for solve tech problems",
    image: "/images/icons/curriculum.png",
  },
  {
    title: "Industry Veterans",
    description:
      "Insights from Industry Veterans: Unparalleled Sessions by Microsoft, Amazon, Google Engineers",
    image: "/images/icons/industry-veterans.png",
  },
  {
    title: "Mock Interviews",
    description:
      "Sharpen Your Skills with Mock Interviews: Prepare to Excel with Realistic Simulations",
    image: "/images/icons/mock-interviews.png",
  },
  {
    title: "Referrals",
    description:
      "Unlock Opportunities with Referrals: Tap into Networks of Industry Insiders. Leverage powerful connections to open doors to your dream career.",
    image: "/images/icons/referral.png",
  },
];
