import { Container } from "@mui/system";
import React from "react";
import FAQPage from "../../components/faqPage/faqPage";
import ContactUs from "../../components/contactPage/contactUs";

const FAQ = () => {
  return (
    <Container sx={{ mt: 8 }}>
      <FAQPage />
      <ContactUs/>
    </Container>
  );
};

export default FAQ;
