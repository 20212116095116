import React, { FC } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { FooterNavigation, FooterSocialLinks } from "../footer";
import { Logo } from "../logo";

const Footer: FC = () => {
  return (
    <Box component="footer" sx={{ py: { xs: 6, md: 10 } }}>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <Box sx={{ width: { xs: "100%", md: 360 }, mb: { xs: 3, md: 0 } }}>
              <Logo />
              <Typography
                variant="subtitle1"
                sx={{ letterSpacing: 1, mb: 2, mt: 2 }}
              >
                InterviewTopper is an online learning platform that has been
                operating since 2023 until now.
              </Typography>
              <FooterSocialLinks />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <FooterNavigation />
          </Grid>
        </Grid>
        <Typography
          variant="caption"
          sx={{ textAlign: "center", mt: 4, color: "#888", fontSize: "0.9rem", fontWeight: "bold" }}
        >
          Ⓛ Licensed under INTERVIEW TOPPER SERVICES
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
