import { Typography } from "@mui/material";
import { Container } from "@mui/system";

export default function PrivacyPolicy() {
  return (
    <Container>
      <Typography variant="h2">Privacy Policy</Typography>
      <Typography>
        This Privacy Policy applies to the courses offered by InterviewTopper,
        accessible from interviewtopper.com, courses.interviewtopper.com. 
        These websites will be collectively referred to as "interviewtopper.com". 
        The document outlines the types of information collected and recorded by 
        
        interviewtopper.com and how we use it.
      </Typography>

      <Typography variant="h2">Consent</Typography>
      <Typography>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </Typography>

      <Typography variant="h2">Information We Collect</Typography>
      <Typography>
        The personal information that you are asked to provide, and the reasons
        why you are asked to provide it, will be made clear to you at the point
        we ask for your details. If you contact us directly, we may receive
        additional information about you, such as your name, email address,
        phone number, the contents of the message, and/or attachments you may
        send us, and any other information you choose to provide. When you
        register for an account, we may ask for your contact information,
        including items such as name, company name, address, email address, and
        telephone number.
      </Typography>

      <Typography variant="h2">How We Use Your Information</Typography>
      <Typography>
        We use the information we collect in various ways, including to:
        <ul>
          <li>Provide, operate, and maintain our website</li>
          <li>Improve, personalize, and expand our website</li>
          <li>Understand and analyze how you use our website</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, updates, and marketing
            purposes
          </li>
          <li>Send you emails</li>
          <li>Find and prevent fraud</li>
        </ul>
      </Typography>

      <Typography variant="h2">Log Files</Typography>
      <Typography>
        Like many other websites, interviewtopper.com follows a standard
        procedure of using log files. These files log visitors when they visit
        websites. The information collected includes internet protocol (IP)
        addresses, browser type, Internet Service Provider (ISP), date and time
        stamp, referring/exit pages, and possibly the number of clicks. This
        information is used for analyzing trends, administering the site,
        tracking users' movement on the website, and gathering demographic
        information. It is not linked to any information that is personally
        identifiable.
      </Typography>

      <Typography variant="h2">Cookies and Web Beacons</Typography>
      <Typography>
        interviewtopper.com uses cookies to store information about visitors'
        preferences and to customize web page content based on visitors' browser
        type and other information. For more information about cookies, please
        read "What Are Cookies".
      </Typography>

      <Typography variant="h2">
        Advertising Partners Privacy Policies
      </Typography>
      <Typography>
        Third-party ad servers or ad networks may use technologies like cookies,
        JavaScript, or Web Beacons to measure the effectiveness of their
        advertising campaigns and/or personalize the advertising content you see
        on websites. Interviewtopper.com has no access to or control over these
        cookies used by third-party advertisers. Please consult the respective
        Privacy Policies of these third-party ad servers for more detailed
        information on their practices and instructions on how to opt-out of
        certain options.
      </Typography>

      <Typography variant="h2">Third Party Privacy Policies</Typography>
      <Typography>
        Interviewtopper.com's Privacy Policy does not apply to other advertisers
        or websites. Therefore, we advise you to consult the respective Privacy
        Policies of these third-party ad servers for more detailed information.
        You can also choose to disable cookies through your individual browser
        settings. For more information about cookie management with specific web
        browsers, please refer to the browsers' respective websites.
      </Typography>

      <Typography variant="h2">CCPA Privacy Rights</Typography>
      <Typography>
        Under the California Consumer Privacy Act (CCPA), California consumers
        have the right to request disclosure of the categories and specific
        pieces of personal data collected by a business, request deletion of
        their personal data, and request that a business that sells personal
        data not sell their personal data. If you would like to exercise any of
        these rights, please contact us.
      </Typography>

      <Typography variant="h2">GDPR Data Protection Rights</Typography>
      <Typography>
        We respect your data protection rights. You have the right to:
        <ul>
          <li>Access and obtain a copy of your personal data</li>
          <li>Rectify any inaccurate or incomplete personal data</li>
          <li>Erase your personal data under certain conditions</li>
          <li>
            Restrict the processing of your personal data under certain
            conditions
          </li>
          <li>
            Object to the processing of your personal data under certain
            conditions
          </li>
          <li>
            Request the transfer of your personal data to another organization
            under certain conditions
          </li>
        </ul>
        If you would like to exercise any of these rights, please contact us.
      </Typography>

      <Typography variant="h2">Children's Information</Typography>
      <Typography>
        We prioritize the protection of children using the internet. Parents and
        guardians are encouraged to observe, participate in, and/or monitor and
        guide their children's online activity. Interviewtopper.com does not
        knowingly collect any Personal Identifiable Information from children
        under the age of 13. If you believe your child has provided this kind of
        information on our website, please contact us immediately, and we will
        make our best efforts to promptly remove such information from our
        records.
      </Typography>
    </Container>
  );
}
