import {
  Box,
  Fab,
  ImageList,
  ImageListItem,
  Modal,
  TextField,
  Typography,
  useTheme,
  Zoom,
} from "@mui/material";
import { Container, flexbox } from "@mui/system";
import React, { FC, useRef } from "react";
import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import ContactForm from "./contact-form";

const fabStyle = {
  position: "fixed",
  bottom: 40,
  right: 40,
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  ml: "auto",
  display: "flex",
  justifyContent: "center",
};

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const ContactFormModal: FC<IProps> = (props: IProps) => {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <ContactForm handleClose={props.handleClose} />
      </Box>
    </Modal>
  );
};

const ContactFab: FC = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <>
      <ContactFormModal open={open} handleClose={handleClose} />
      <Zoom
        in={true}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${transitionDuration.exit}ms`,
        }}
        unmountOnExit
      >
        <Fab
          sx={fabStyle}
          aria-label={"Contact us"}
          color="primary"
          onClick={handleOpen}
        >
          <MessageRoundedIcon />
        </Fab>
      </Zoom>
    </>
  );
};

export default ContactFab;
