import React from "react";
import LibraryPage from "../../components/library/library";
import Links from "../../components/library/links";
import BlogPage from "../../components/library/BlogPage";

const Library = () => {
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);

  const blogId = queryParams.get("blogId");

  if (blogId) {
    return <BlogPage blogId = {blogId}/>
  }

  return <Links />;
};

export default Library;
