import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { styled } from "@mui/system";

const RootContainer = styled("div")({
  width: "100%",
});

const StyledListItem = styled(ListItem)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  border: "1px solid #ddd",
  borderRadius: "8px",
  padding: "8px",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
});

const TimeContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

const LecturesContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

const TimeIcon = styled(AccessTimeIcon)({
  marginRight: "8px",
  fontSize: "18px",
});

const TimeText = styled(Typography)({
  fontSize: "12px",
  marginRight: "8px",
});

const LecturesText = styled(Typography)({
  fontSize: "12px",
});

const CourseLectureList = (props: any) => {
  let courses = props.lectures;
  return (
    <RootContainer>
      <List>
        {courses.map((course: any, index: any) => (
          <StyledListItem key={index}>
            <ListItemText primary={course.name} />
            <div>
              <TimeContainer>
                <TimeIcon />
                <TimeText variant="body2">{course.time}</TimeText>
              </TimeContainer>
              <LecturesContainer>
                <OndemandVideoIcon />
                <LecturesText variant="body2">
                  {course.lectures.toString()} lectures
                </LecturesText>
              </LecturesContainer>
            </div>
          </StyledListItem>
        ))}
      </List>
    </RootContainer>
  );
};

export default CourseLectureList;
