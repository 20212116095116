import type { Course } from "../interfaces/course";

export const data: Array<Course> = [
  {
    id: "dsa-crash-course",
    cover: "/images/courses/DSACrashCourse.webp",
    title: "DSA Crash Course",
    rating: 5,
    ratingCount: 8,
    price: 1699,
    category: "Intermediate",
  },
  {
    id: "dsa-complete-course",
    cover: "/images/courses/CompleteDSACourse.png",
    title: "DSA Complete Course",
    rating: 5,
    ratingCount: 15,
    price: 18999,
    category: "Beginner",
  },
  {
    id: "lld-crash-course",
    cover: "/images/courses/christopher-gower-m_HRfLhgABo-unsplash.jpg",
    title: "Low Level Design Crash Course",
    rating: 4,
    ratingCount: 7,
    price: 2999,
    category: "Intermediate",
  },
  {
    id: "lld-complete-course",
    cover: "/images/courses/LLDCrashCourse.png",
    title: "Low Level Design Complete Course",
    rating: 4,
    ratingCount: 12,
    price: 25999,
    category: "Beginner",
  },
  {
    id: "hld-crash-course",
    cover: "/images/courses/SystemDesign.png",
    title: "High Level Design Crash Course",
    rating: 4,
    ratingCount: 32,
    price: 4499,
    category: "Intermediate",
  },
  {
    id: "hld-complete-course",
    cover: "/images/courses/HLDCourse.gif",
    title: "High Level Design Complete Course",
    rating: 5,
    ratingCount: 14,
    price: 34999,
    category: "Beginner",
  },
  {
    id: "",
    cover: "/images/courses/grovemade-RvPDe41lYBA-unsplash.jpg",
    title: "Computer Science 101 Course",
    rating: 4,
    ratingCount: 6,
    price: 9999,
    category: "Beginner",
  },
];
