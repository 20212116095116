import type { Navigation } from "../interfaces/navigation";

export const navigations: Navigation[] = [
  {
    label: "Home",
    path: "/", // '/',
  },
  {
    label: "Courses",
    path: "/courses", // '/popular-course',
  },
  {
    label: "FAQ",
    path: "/faq", // '/testimonial',
  },
  {
    label: "Library",
    path: "/library",
  },
];
