import { Box } from "@mui/material";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Footer } from "../components/footer";
import { Header } from "../components/header";
import Courses from "../pages/courses";
import FAQ from "../pages/faq";
import Home from "../pages/home";
import PaymentElement from "../components/payment/payment-component";
import Profile from "../pages/profile";
import Library from "../pages/library";
import { AboutUs, PrivacyPolicy, RefundPolicy, TNC } from "../pages/policy";

export default function Routes() {
  return (
    <>
      <Router>
        <Box component="main">
          <Header />
          <Switch>
            <Route path="/courses">
              <Courses />
            </Route>
            <Route path="/faq">
              <FAQ />
            </Route>
            <Route path="/payment">
              <PaymentElement />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
            <Route path="/library">
              <Library />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/refund-policy">
              <RefundPolicy />
            </Route>
            <Route path="/terms-and-condtitions">
              <TNC />
            </Route>
            <Route path="/about-us">
              <AboutUs />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
          </Switch>
          <Footer />
        </Box>
      </Router>
    </>
  );
}
