import Box from "@mui/system/Box";
import React from "react";
import CourseDetails from "../../components/course/course-details";
import dsaCrashCourse from "../../data/courses/dsa-crash-course.json";
import lldCrashCourse from "../../data/courses/lld-crash-course.json";
import hldCrashCourse from "../../data/courses/hld-crash-course.json";
import dsaCompleteCourse from "../../data/courses/dsa-complete-course.json";
import lldCompleteCourse from "../../data/courses/lld-complete-course.json";
import hldCompleteCourse from "../../data/courses/hld-complete-course.json";

const CourseComp = () => {
  const renderedItems: JSX.Element[] = [];
  let courses = [
    dsaCrashCourse,
    lldCrashCourse,
    hldCrashCourse,
    dsaCompleteCourse,
    lldCompleteCourse,
    hldCompleteCourse
  ];
  let searchParams = new URLSearchParams(document.location.search);
  let paramCourseId = searchParams?.get("courseId");

  courses.forEach((course) => {
    if (paramCourseId) {
      if (course.id == paramCourseId) {
        renderedItems.push(<CourseDetails courseDetail={course} />);
      }
    } else {
      renderedItems.push(<CourseDetails courseDetail={course} />);
    }
  });

  return renderedItems;
};

const Courses = () => {
  let finalComp = CourseComp();

  return <Box sx={{ flexGrow: 1 }}>{finalComp}</Box>;
};

export default Courses;
