import { Typography } from "@mui/material";
import { Container } from "@mui/system";

export default function RefundPolicy() {
  return (
    <Container>
      <Typography variant="h2">Refunds and Returns Policy</Typography>
      <Typography>
        Thank you for shopping with us! We want to ensure you have a
        satisfactory experience, and we have outlined our policy on refunds and
        returns for the products you purchased with us.
      </Typography>

      <Typography variant="h2">Interpretation</Typography>
      <Typography>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions.
      </Typography>

      <Typography variant="h2">Your Order Cancellation Rights</Typography>
      <Typography>
        We offer a 3-day refund window from the course commencement date on all
        courses, no questions asked. If you are not completely satisfied with
        your purchase, you have the right to cancel your order within 3 days.
        To exercise your right of cancellation, you must inform us of your
        decision by sending a clear statement via email to:
        interviewtopper23@gmail.com
      </Typography>

      <Typography variant="h2">Contact Us</Typography>
      <Typography>
        If you have any questions about our Refunds and Returns Policy, please
        feel free to contact us via email at interviewtopper23@gmail.com. We
        are here to assist you and ensure your satisfaction.
      </Typography>
    </Container>
  );
}
