import React, { FC } from "react";
// import Image from 'next/image'
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { data } from "../../data/feature.data";
import { Paper } from "@mui/material";

interface LinearProgressProps {
  order: number;
}

const BorderLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== "color",
})<LinearProgressProps>(({ theme, order }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    ...(order === 1 && {
      backgroundColor: "#f303ff",
    }),
    ...(order === 2 && {
      backgroundColor: "#26e8bd",
    }),
    ...(order === 3 && {
      backgroundColor: "#0063ff",
    }),
  },
}));

const HomeFeature: FC = () => {
  return (
    <Box
      id="feature"
      sx={{ py: { xs: 10, md: 14 }, backgroundColor: "background.paper" }}
    >
      <Container>
        <Typography
          component="h1"
          sx={{
            fontSize: 40,
            position: "relative",
            letterSpacing: 1.5,
            fontWeight: "bold",
            lineHeight: 1.3,
          }}
        >
          Features designed{" "}
          <Typography
            component="mark"
            sx={{
              position: "relative",
              color: "primary.main",
              fontSize: "inherit",
              fontWeight: "inherit",
              backgroundColor: "unset",
            }}
          >
            for Growth
          </Typography>
        </Typography>
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            mt: 6,
          }}
        >
          {data.map(({ title, description, image }, index) => (
            <Grid item xs={12} md={4}>
              <Paper variant="outlined" elevation={3}>
                <Box
                  sx={{
                    px: 1.5,
                    pt: 1.5,
                    pb: 3,
                    boxShadow: 1,
                    borderRadius: 4,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "fit-content",
                    height: "fit-content",
                    justifyContent: "center",
                    textAlign: "center",
                    minHeight: 200,
                    maxHeight: 400,
                  }}
                >
                  <Box
                    sx={{
                      p: 1,
                    }}
                  >
                    <img src={image} alt={title} width={100} height={100} />
                  </Box>
                  <Typography variant="h2">{title}</Typography>
                  <Typography>{description}</Typography>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default HomeFeature;
