import React, { useState, useEffect } from "react";
import {
  Appearance,
  StripeElementsOptions,
  StripeElementsOptionsMode,
  loadStripe,
} from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./checkout-form";
import { AuthContext } from "../../context/auth-context";
import { STRIPE_PUBLISHABLE_KEY } from "./stripe.config";
import { API_ROOT } from "../../utils/constants";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export default function PaymentComponent() {
  const [clientSecret, setClientSecret] = useState("");
  const { user } = React.useContext(AuthContext);
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(API_ROOT + "/_api/v1/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user?.accessToken,
      },
      body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, [user]);

  const appearance: Appearance = {
    theme: "stripe",
  };
  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  return (
    <div className="PaymentApp">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}
