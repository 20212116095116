import React, { FC } from "react";
// import Link from 'next/link'
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import type { Navigation } from "../../interfaces/navigation";
import { navigations as headerNavigations } from "../../data/navigation.data";
import { FooterSectionTitle } from "../footer";
import { Box } from "@mui/material";

const courseMenu: Array<Navigation> = [
  {
    label: "UI/UX Design",
    path: "#",
  },
  {
    label: "Mobile Development",
    path: "#",
  },
  {
    label: "Machine Learning",
    path: "#",
  },
  {
    label: "Web Development",
    path: "#",
  },
];

const pageMenu = headerNavigations;

const companyMenu: Array<Navigation> = [
  { label: "Privacy Policy", path: "/privacy-policy" },
  { label: "Terms & Condition", path: "/terms-and-condtitions" },
  { label: "Refund Policy", path: "/refund-policy" },
  { label: "Contact Us", path: "/faq" },
  { label: "About Us", path: "/about-us"}
];

interface NavigationItemProps {
  label: string;
  path: string;
}

const NavigationItem: FC<NavigationItemProps> = ({ label, path }) => {
  return (
    <MuiLink
      href={path}
      underline="none"
      sx={{
        display: "block",
        mb: 1,
        "& > div": { display: "none" },

        "&.current>div": { display: "block" },

        "&:hover": {
          color: "secondary.main",
          "&>div": {
            display: "block",
          },
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 12,
          transform: "rotate(3deg)",
          "& img": { width: 44, height: "auto" },
        }}
      >
        {/* eslint-disable-next-line */}
        <img src="/images/headline-curve.svg" alt="Headline curve" />
      </Box>
      {label}
    </MuiLink>
  );
};

const FooterNavigation: FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}></Grid>
      <Grid item xs={12} md={4}>
        <FooterSectionTitle title="Menu" />
        {pageMenu.map(({ label, path }, index) => (
          <NavigationItem key={index + path} label={label} path={path} />
        ))}
      </Grid>
      <Grid item xs={12} md={4}>
        <FooterSectionTitle title="Legal and Contact" />
        {companyMenu.map(({ label, path }, index) => (
          <NavigationItem key={index + path} label={label} path={path} />
        ))}
      </Grid>
    </Grid>
  );
};

export default FooterNavigation;
