import styled from "@emotion/styled";
import { useState } from "react";
import CheckoutRedirect from "../payment/checkout-redirect";
import LoadingButton from "@mui/lab/LoadingButton";
import { Theme, useTheme } from "@mui/material";

interface IProps {
  courseDetail: any;
}

export default function CheckoutLoadingButton(props: IProps) {
  const [loading, setLoading] = useState(false);
  const theme: Theme = useTheme();
  return (
    <CheckoutRedirect
      data={props.courseDetail}
      completed={() => setLoading(false)}
      onRedirect={() => setLoading(true)}
    >
      <LoadingButton
        variant="contained"
        color="primary"
        loading={loading}
        sx={{
          borderRadius: 2,
          "&:hover": {
            background: theme.palette.secondary.dark,
          },
        }}
      >
        Enroll Now
      </LoadingButton>
    </CheckoutRedirect>
  );
}
