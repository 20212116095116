import { CacheProvider } from "@emotion/react";
import { Box, CssBaseline } from "@mui/material";
import React from "react";
import { Footer } from "./components/footer";
import { Header } from "./components/header";
import { AuthContextProvider } from "./context/auth-context";
import Home from "./pages/home";
import { MUIProvider } from "./providers";
import Routes from "./router";
import { createEmotionCache } from "./utils";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
// console.log(process.env)
export default function App() {
  return (
    <>
      <CacheProvider value={clientSideEmotionCache}>
        <AuthContextProvider>
          <MUIProvider>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <Routes />
          </MUIProvider>
        </AuthContextProvider>
      </CacheProvider>
    </>
  );
}
