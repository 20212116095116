import { useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import { API_ROOT } from "../../utils/constants";

interface IProps {
  data: any;
  children: JSX.Element;
  completed: () => void;
  onRedirect: () => void;
}
export default function CheckoutRedirect(props: IProps) {
  const { user } = useContext(AuthContext);
  const createCheckoutSession = () => {
    props.onRedirect();

    // Uncomment for RazorPay
    // window.location.href = props.data.paymentPage;
    const item = convertToLineItem(props.data);

    fetch(API_ROOT + "/_api/v1/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user?.accessToken,
      },
      body: JSON.stringify({ items: [item] }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (props.completed) props.completed();
        window.location.href = res.url;
      });
  };

  return (
    <div className="checkout-redirect" onClick={createCheckoutSession}>
      {props.children}
    </div>
  );
}

function convertToLineItem(data: any) {
  return {
    price_data: {
      currency: "inr",
      product_data: {
        name: data.name,
        description: data.overview,
      },
      unit_amount: 100 * data.price.advanceDiscount,
    },
    quantity: 1,
  };
}
