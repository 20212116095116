import { Box, ImageList, ImageListItem, Typography } from "@mui/material";
import { Container, flexbox } from "@mui/system";
import React, { FC, useRef } from "react";
import { companiesData } from "../../data/companies-data";

const HomeCompanies: FC = () => {
  return (
    <Container sx={{ justifyContent: "center" }}>
      <Typography
        component="h1"
        sx={{
          fontSize: 40,
          position: "relative",
          letterSpacing: 1.5,
          fontWeight: "bold",
          lineHeight: 1.3,
        }}
      >
        Helping Learners Reach{" "}
        <Typography
          component="mark"
          sx={{
            position: "relative",
            color: "primary.main",
            fontSize: "inherit",
            fontWeight: "inherit",
            backgroundColor: "unset",
          }}
        >
          Top Product Companies
        </Typography>
      </Typography>

      <Box sx={{ mb: 4, width: { xs: "100%", md: "70%" } }}>
        <Typography sx={{ color: "text.secondary", lineHeight: 1.6 }}>
          We are preparing thousands of learners to get hired in top tech
          companies globally.
        </Typography>
      </Box>
      <Container
        sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {companiesData.map((image, i) => (
          <Box key={image.img} sx={{ m: 2 }}>
            <img
              src={image.src}
              loading="lazy"
              height=""
              width="150"
              sizes={image.sizes}
              srcSet={image.srcSet}
              id={i.toLocaleString()}
              alt=""
            />
          </Box>
        ))}
      </Container>
    </Container>
  );
};

export default HomeCompanies;
