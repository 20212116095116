import React, { FC, ReactNode, useState } from "react";
import { ThemeProvider, useMediaQuery } from "@mui/material";

import { createTheme } from "../theme";

interface Props {
  children: ReactNode;
}

export interface IThemeContext {
  darkMode: boolean;
  toggleDarkMode: (value: boolean) => void;
}

export const ThemeContext = React.createContext<IThemeContext>({
  darkMode: false,
  toggleDarkMode: (value: boolean) => {},
});

const MUIProvider: FC<Props> = ({ children }) => {
  // Get OS-level preference for dark mode
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  // state: boolean ; true == use dark mode
  const [darkMode, setDarkMode] = useState(prefersDarkMode);

  const toggleDarkMode = (value: boolean) => {
    setDarkMode(value);
  };

  const theme = createTheme(darkMode);

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default MUIProvider;
