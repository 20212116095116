import React, { useState, useEffect  } from "react";
import ProfilePage from "../../components/UserProfile/ProfilePage";
import { AuthContext } from "../../context/auth-context";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { API_ROOT } from "../../utils/constants";

const Profile = () => {
  const { user } = React.useContext(AuthContext);

  const fetchProfile = async () => {
    const response = await fetch(API_ROOT + "/_api/v1/user-profile?userId=" + user.uid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
      }
    });

    if (!response.ok) {
      return null;
    } else {
      return response.json()
    }
  }

  // State to store loader status
  const [loader, setLoader] = useState(true);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    fetchProfile()
      .then((res) => {
        setUserProfile(res);
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      })
  }, [user])

  if (user == null) {
    return (
      <h3 style={{ textAlign: "center" }}> Please Login to view this page </h3>
    );
  }

  if (loader) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress style={{marginLeft: "auto", marginRight: "auto", marginTop: "16px"}}/>
      </Box>
    );
  }

  return <ProfilePage userDetails={user} userProfile = {userProfile} />;
};

export default Profile;
