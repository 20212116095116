// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB4o4sMbiXa9VZBiN8gjEQAlzQZxhUu0jw",
  authDomain: "interviewtopper.firebaseapp.com",
  projectId: "interviewtopper",
  storageBucket: "interviewtopper.appspot.com",
  messagingSenderId: "413705840549",
  appId: "1:413705840549:web:817dffcce0254fdb9e2a4c",
  measurementId: "G-7PBF393F0W",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();
