import React, { FC } from "react";
import Box from "@mui/material/Box";
import { StyledButton } from "../styled-button";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Facebook, Google, GitHub } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import { UserAuth } from "../../context/auth-context";
import { Avatar, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const Persona = () => {
  const { logOut, user } = UserAuth();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const name = user.displayName;
  const image = user.photoURL;

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt={name} src={image} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Link
          to="/profile"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          <MenuItem onClick={handleCloseUserMenu}>
            <Typography textAlign="center">Profile</Typography>
          </MenuItem>
        </Link>
        <MenuItem
          onClick={() => {
            logOut();
            handleCloseUserMenu();
          }}
        >
          <Typography textAlign="center">LogOut</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default function AuthNavigation() {
  const { googleSignIn, githubSignIn, user } = UserAuth();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    } finally {
      handleClose();
    }
  };

  const handleGithubSignIn = async () => {
    try {
      await githubSignIn();
    } catch (error) {
      console.log(error);
    } finally {
      handleClose();
    }
  };

  return (
    <div>
      {!user && (
        <Box sx={{ "& button:first-child": { mr: 2 } }}>
          <StyledButton disableHoverEffect={true} onClick={handleClickOpen}>
            Login
          </StyledButton>
        </Box>
      )}
      {user && (
        <>
          <Persona />
        </>
      )}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Interview Topper
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Stack direction="row" alignItems="center">
            <Box
              component="img"
              sx={{
                height: "60%",
                width: "60%",
                // maxHeight: { xs: 233, md: 167 },
                // maxWidth: { xs: 350, md: 250 },
              }}
              alt="Login."
              src="/images/loginIlustration.png"
            />
            <Box sx={{ width: "100%" }}>
              <Stack spacing={2}>
                <Button
                  variant="outlined"
                  onClick={handleGoogleSignIn}
                  startIcon={<Google onClick={handleGoogleSignIn} />}
                  style={{
                    backgroundColor: "#dd4b39",
                    color: "#fff",
                  }}
                >
                  Google
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleGithubSignIn}
                  startIcon={<GitHub onClick={handleGithubSignIn} />}
                  style={{
                    backgroundColor: "#333",
                    color: "#fff",
                  }}
                >
                  Github
                </Button>

                {/* Disabling Facebook login as of now. */}
                {/* <Button
                  variant="outlined"
                  startIcon={<Facebook />}
                  style={{
                    backgroundColor: "#0077b5",
                    color: "#fff",
                  }}
                >
                  Facebook
                </Button> */}

              </Stack>
            </Box>
          </Stack>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
